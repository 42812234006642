import Swiper from "../vendor/swiper/swiper.min.js";
import "bootstrap/js/dist/tooltip";

jQuery(function ($) {
  $(document).ready(function () {
    function getBaseUrl() {
      let re = new RegExp(
        /^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/
      );
      let urlArr = re.exec(window.location.href);
      return urlArr[0];
    }

    var web_site = getBaseUrl();

    const vars = {
      begravningsbyra_slug: "begravningsbyra",
      page_tack_medceremoni_slug: "tack-medceremoni",
      role_ordna_begravning_slug: "ordna-begravning",
      prod_cat_slug: "produkt-kategori",
      allergier: "Inga allergier",
      page_anmalan_slug: "anmalan",
      planera_slug: "planera-begravning",
      prod_cat_blommor_slug: "blommor",
    };

    let dataFromJson = [];
    $.ajax({
      url: web_site + "/wp-content/themes/fenix4/_env/_js_trans.php",
      type: "POST",
      data: JSON.stringify(vars),
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      async: false,
      success: function (json) {
        dataFromJson = json;
      },
    });

    // COOKIES
    // Helper functions:

    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    var cityCoockieExists = false;
    var cityCookie;

    function checkCookie(cname) {
      var cookie = getCookie(cname);
      if (cookie != "") {
        // console.log("Cookie is: " + cookie);
        cityCoockieExists = true;
        cityCookie = getCookie(cname);
      } else {
        // console.log("There is no fenix4city cookie yet...");
        cityCoockieExists = false;
      }
    }

    // function to add [CITY] link to logo in header
    function addCityLinkToMenuLogo() {
      // if ( $( 'a.legal-city' ).length ) {
      //     // checkCookie('fenix4city');
      //     if ( cityCoockieExists ) {
      //         $('a.legal-city').each ( function() {
      //             $( this ).attr("href", $( this ).attr("href") + cityCookie + "/");
      //         });
      //     }
      // }
      if ($("a.funeral-city").length) {
        // checkCookie('fenix4city');
        if (cityCoockieExists) {
          $("a.funeral-city").each(function () {
            $(this).attr("href", $(this).attr("href") + cityCookie + "/");
          });
        }
      }
    }

    function checkIsCookieIsIn304Cities(cook) {
      var cities304Arr = [
        "ale",
        "alingsas",
        "almhult",
        "alvdalen",
        "alvesta",
        "alvkarleby",
        "alvsbyn",
        "amal",
        "aneby",
        "ange",
        "angelholm",
        "arboga",
        "are",
        "arjang",
        "arjeplog",
        "arvidsjaur",
        "arvika",
        "asele",
        "askersund",
        "astorp",
        "atvidaberg",
        "avesta",
        "bastad",
        "bengtsfors",
        "berg",
        "bjurholm",
        "bjuv",
        "blekinge",
        "boden",
        "bollebygd",
        "bollnas",
        "boras",
        "borgholm",
        "borlange",
        "botkyrka",
        "boxholm",
        "bracke",
        "bromolla",
        "burlov",
        "dalarna",
        "dals-ed",
        "danderyd",
        "degerfors",
        "dorotea",
        "eda",
        "ekero",
        "eksjo",
        "emmaboda",
        "enkoping",
        "eskilstuna",
        "eslov",
        "essunga",
        "fagersta",
        "falkenberg",
        "falkoping",
        "falun",
        "fargelanda",
        "filipstad",
        "finspang",
        "flen",
        "forshaga",
        "gagnef",
        "gallivare",
        "gavle",
        "gavleborg",
        "gislaved",
        "gnesta",
        "gnosjo",
        "goteborg",
        "gotene",
        "gotland",
        "grastorp",
        "grums",
        "gullspang",
        "habo",
        "hagfors",
        "halland",
        "hallefors",
        "hallsberg",
        "hallstahammar",
        "halmstad",
        "hammaro",
        "haninge",
        "haparanda",
        "harjedalen",
        "harnosand",
        "harryda",
        "hassleholm",
        "heby",
        "hedemora",
        "helsingborg",
        "herrljunga",
        "hjo",
        "hofors",
        "hoganas",
        "hogsby",
        "hoor",
        "horby",
        "huddinge",
        "hudiksvall",
        "hultsfred",
        "hylte",
        "jamtland",
        "jarfalla",
        "jokkmokk",
        "jonkoping",
        "kalix",
        "kalmar",
        "karlsborg",
        "karlshamn",
        "karlskoga",
        "karlskrona",
        "karlstad",
        "katrineholm",
        "kavlinge",
        "kil",
        "kinda",
        "kiruna",
        "klippan",
        "knivsta",
        "koping",
        "kramfors",
        "kristianstad",
        "kristinehamn",
        "krokom",
        "kronoberg",
        "kumla",
        "kungalv",
        "kungsbacka",
        "kungsor",
        "laholm",
        "landskrona",
        "laxa",
        "lekeberg",
        "leksand",
        "lerum",
        "lessebo",
        "lidingo",
        "lidkoping",
        "lilla-edet",
        "lindesberg",
        "linkoping",
        "ljungby",
        "ljusdal",
        "ljusnarsberg",
        "lomma",
        "ludvika",
        "lulea",
        "lund",
        "lycksele",
        "lysekil",
        "mala",
        "malmo",
        "malung-salen",
        "mariestad",
        "mark",
        "markaryd",
        "mellerud",
        "mjolby",
        "molndal",
        "monsteras",
        "mora",
        "morbylanga",
        "motala",
        "mullsjo",
        "munkedal",
        "munkfors",
        "nacka",
        "nassjo",
        "nora",
        "norberg",
        "nordanstig",
        "nordmaling",
        "norrbotten",
        "norrkoping",
        "norrtalje",
        "norsjo",
        "nybro",
        "nykoping",
        "nykvarn",
        "nynashamn",
        "ockelbo",
        "ockero",
        "odeshog",
        "olofstrom",
        "orebro",
        "orkelljunga",
        "ornskoldsvik",
        "orsa",
        "orust",
        "osby",
        "oskarshamn",
        "osteraker",
        "ostergotland",
        "ostersund",
        "osthammar",
        "ostra-goinge",
        "ovanaker",
        "overkalix",
        "overtornea",
        "oxelosund",
        "pajala",
        "partille",
        "perstorp",
        "pitea",
        "ragunda",
        "rattvik",
        "robertsfors",
        "ronneby",
        "saffle",
        "sala",
        "salem",
        "sandviken",
        "sater",
        "savsjo",
        "sigtuna",
        "simrishamn",
        "sjobo",
        "skane",
        "skara",
        "skelleftea",
        "skinnskatteberg",
        "skovde",
        "skurup",
        "smedjebacken",
        "soderhamn",
        "soderkoping",
        "sodermanland",
        "sodertalje",
        "solleftea",
        "sollentuna",
        "solna",
        "solvesborg",
        "sorsele",
        "sotenas",
        "staffanstorp",
        "stenungsund",
        "stockholm",
        "storfors",
        "storuman",
        "strangnas",
        "stromstad",
        "stromsund",
        "sundbyberg",
        "sundsvall",
        "sunne",
        "surahammar",
        "svalov",
        "svedala",
        "svenljunga",
        "taby",
        "tanum",
        "tibro",
        "tidaholm",
        "tierp",
        "timra",
        "tingsryd",
        "tjorn",
        "tomelilla",
        "toreboda",
        "torsas",
        "torsby",
        "tranas",
        "tranemo",
        "trelleborg",
        "trollhattan",
        "trosa",
        "tyreso",
        "uddevalla",
        "ulricehamn",
        "umea",
        "upplands-bro",
        "upplands-vasby",
        "uppsala",
        "uppvidinge",
        "vadstena",
        "vaggeryd",
        "valdemarsvik",
        "vallentuna",
        "vanersborg",
        "vannas",
        "vansbro",
        "vara",
        "varberg",
        "vargarda",
        "varmdo",
        "varmland",
        "varnamo",
        "vasteras",
        "vasterbotten",
        "vasternorrland",
        "vastervik",
        "vastmanland",
        "vastra-gotaland",
        "vaxholm",
        "vaxjo",
        "vellinge",
        "vetlanda",
        "vilhelmina",
        "vimmerby",
        "vindeln",
        "vingaker",
        "ydre",
        "ystad",
      ];

      var inArr = $.inArray(cook, cities304Arr);
      inArr = inArr > -1 ? true : false;
      return inArr;
    }

    // #10. On any page:
    //      10.1. - check if coockie exists
    //      10.2. - update links to Platser dynamic pages in Mega menu
    //      10.3. - also update the 'Platser' link
    //  (Kyrkor och kapell, Begravningsplatser, Församlingshem, Bårhus)

    checkCookie("fenix4city");
    if (cityCoockieExists) {
      // addCityLinkToMenuLogo(); // Den: commented out PW-1201: SE canonicals on City Specific Product pages - clean up

      // [UPDATED] 10.2. links in mega menu AND in the sidebar (local-nav)

      var addLinks = checkIsCookieIsIn304Cities(cityCookie);

      if (addLinks) {
        $("div.js-nav-platser-links a").each(function () {
          $(this).attr("href", $(this).attr("href") + cityCookie + "/");
          // console.log('mega and local-nav platser links updated');
        });

        // [UPDATED] 10.3. - footer - update links to Platser and to dynamic funeral type pages with the related city part
        $("a.js-footer-platser-link").each(function () {
          $(this).attr("href", $(this).attr("href") + cityCookie + "/");
          // console.log('footer platser links updated');
        });
      }
    }

    // checkCookie('fenix4city');

    // Allowed: letters, spaces, dash, apostrophe
    $.validator.addMethod(
      "lettersonly",
      function (value, element) {
        return (
          this.optional(element) ||
          /^[a-z åáãâäàéêëèíîïìóõôöòøúûüùçñ\-'’]+$/i.test(value)
        );
      },
      "Vänligen endast bokstäver."
    );

    $.validator.addMethod(
      "textComment",
      function (value, element) {
        return (
          this.optional(element) ||
          /^[a-z0-9!?.,_ åáãâäàéêëèíîïìóõôöòøúûüùçñ\-'’"\n]+$/i.test(value)
        );
      },
      "Endast bokstäver och siffror vänligen."
    );

    $("#commentForm").validate({
      rules: {
        name: {
          required: true,
          lettersonly: true,
        },
        email: {
          required: true,
          email: true,
        },
        comment: {
          required: true,
          textComment: true,
        },
      },
      focusCleanup: true,
      onfocusout: false,
      onkeyup: false,
      onclick: false,
    });

    $("#commentform").validate({
      rules: {
        author: {
          required: true,
          lettersonly: true,
        },
        email: {
          required: true,
          email: true,
        },
        comment: {
          required: true,
          textComment: true,
        },
      },
      focusCleanup: true,
      onfocusout: false,
      onkeyup: false,
      onclick: false,
    });

    $("#organizeForm").validate({
      rules: {
        bookingdate: "required",
        bookingname: {
          required: true,
          lettersonly: true,
        },
        bookinglastname: {
          required: true,
          lettersonly: true,
        },
        bookingemail: {
          required: false,
          email: true,
        },
        bookingphone: {
          required: true,
          fenixSEPhone: true,
        },
      },
      focusCleanup: true,
      onfocusout: false,
      onkeyup: false,
      onclick: false,
    });
    $("#organiser").validate({
      rules: {
        organizername: {
          required: true,
          lettersonly: true,
        },
        organizerphone: "required",
      },
    });
    $("#kontaktaOssForm").validate({
      rules: {
        kontaktanamn: {
          required: true,
          lettersonly: true,
        },
        kontaktafornamn: {
          required: true,
          lettersonly: true,
        },
        kontaktatelefonnummer: {
          required: true,
          fenixSEPhone: true,
        },
        kontaktaepost: {
          required: false,
          email: true,
        },
        kontaktameddelande: {
          required: false,
          textComment: true,
        },
      },
      focusCleanup: true,
      onfocusout: false,
      onkeyup: false,
      onclick: false,
    });
    $("#personForm").validate({
      rules: {
        personnamn: {
          required: true,
          lettersonly: true,
        },
        persontelefonnummer: {
          required: true,
          fenixSEPhone: true,
        },
        personepost: {
          required: true,
          email: true,
        },
      },
    });
    $("#leadForm").validate({
      rules: {
        leadnamn: {
          required: true,
          lettersonly: true,
        },
        leadtelefonnummer: {
          required: true,
          fenixSEPhone: true,
        },
      },
    });
    $("#bokaMoteForm").validate({
      rules: {
        kontaktanamn: {
          required: true,
          lettersonly: true,
        },
        kontaktafornamn: {
          required: true,
          lettersonly: true,
        },
        kontaktatelefonnummer: {
          required: true,
          fenixSEPhone: true,
        },
        helpselect: "required",
        contactselect: "required",
        kontaktaepost: {
          required: false,
          email: true,
        },
        kontaktameddelande: {
          required: false,
          textComment: true,
        },
      },
      focusCleanup: true,
      onfocusout: false,
      onkeyup: false,
      onclick: false,
    });
    $("#rsvp").validate({
      rules: {
        dropdown: "required",
        namn: {
          required: true,
          lettersonly: true,
        },
        email: {
          required: false,
          email: true,
        },
        telefonnummer: {
          required: true,
          fenixSEPhone: true,
        },
        angeantal: {
          required: true,
          digits: true,
          min: 1,
        },
        allergier: "required",
        jag: "required",
      },
    });
    $("#funnelClientDetails").validate({
      rules: {
        funnelname: {
          required: true,
          lettersonly: true,
        },
        funnellastname: {
          required: true,
          lettersonly: true,
        },
        funnelphone: {
          required: true,
          fenixSEPhone: true,
        },
        funnelemail: {
          required: false,
          email: true,
        },
      },
      focusCleanup: true,
      onfocusout: false,
      onkeyup: false,
      onclick: false,
    });

    var currentCityCookie = getCookie("fenix4city");

    var cityName = $(
      '.cities-list--choices .choices__list--dropdown .choices__list .choices__item--selectable[data-value="' +
        currentCityCookie +
        '" ]'
    ).html();

    if (cityName === undefined) {
      cityName = $(
        ".cities-list--choices .choices__list--single .choices__placeholder"
      ).html();
    }

    if (
      cityName === "Välj ditt område" ||
      cityName === "" ||
      cityName === null ||
      cityName === undefined
    ) {
      $(".header-top-bar__exp, .one-button__exp, .mob-button__exp").attr(
        "data-office-city-name",
        "Sverige"
      );
    } else {
      $(".header-top-bar__exp, .one-button__exp, .mob-button__exp").attr(
        "data-office-city-name",
        cityName
      );
    }

    $(".header-top-bar__exp, .one-button__exp, .mob-button__exp").on(
      "click",
      function () {
        var officeCityName = $(this).data("office-city-name");
        var paCity = [
          "Ekerö",
          "Gotland",
          "Hammarö",
          "Orust",
          "Tjörn",
          "Värmdö",
          "Öckerö",
          "Lidingö",
          "Frösön",
          "Värnhem",
          "Limhamn",
          "Norrmalm",
          "Östermalm",
          "Södermalm",
          "Malmaberg",
          "Norrmalm",
          "Västermalm",
        ];
        if (paCity.includes(officeCityName)) {
          $("span#bokaOfficeCityName").text("på " + officeCityName);
        } else {
          $("span#bokaOfficeCityName").text("i " + officeCityName);
        }
      }
    );

    $("#collapseButton").on("click", function () {
      $("#menu-main-menu-mob").addClass("sidenav-open");
      $("#overlay").css({ display: "block" });
      // $("body").css({"overflow":"hidden"});
      $("body").addClass("fixed-body");
      $("html").addClass("fixed-body");
    });

    $("#collapseButtonClose, #mobMenuTriggerClose").on("click", function () {
      $("#menu-main-menu-mob").removeClass("sidenav-open");
      $("#overlay").css({ display: "none" });
      // $("body").css({"overflow":"visible"});
      $("body").removeClass("fixed-body");
      $("html").removeClass("fixed-body");
    });

    $("#mobNavSearchButton").on("click", function () {
      $("#collapseButtonClose").trigger("click");
      $("#mob-navbar-search-icon-sec").trigger("click");
    });

    $("#overlay").on("click", function () {
      $("#overlay").css({ display: "none" });
      $("#menu-main-menu-mob").removeClass("sidenav-open");
      // $("body").css({"overflow":"visible"});
      $("body").removeClass("fixed-body");
      $("html").removeClass("fixed-body");
    });

    $(".mob-links").on("click", function () {
      event.preventDefault();
      var step = $(this).data("link");
      // console.log(step);
      $(".mob-steps").addClass("hidden-mob-step");
      $("#mob" + step).removeClass("hidden-mob-step");
    });

    $("#searchSwitcher").on("click", function () {
      // alert("clicked sec");
      $("form.form-inline input").val("");
      $("#menuItemsWrapper").toggleClass("hidden-search");
      $("#searchToShow").toggleClass("hidden-search");
      initJQueryClicks();
      $("#searchField").focus();
    });

    $("#mob-navbar-search-icon-sec").on("click", function () {
      // alert("clicked sec");
      // $("#menuItemsWrapper").toggleClass("hidden-search");
      $("form.form-inline input").val("");
      $("#searchToShow").toggleClass("hidden-search");
      $("#first-nav.navbar-brand").toggleClass("hidden-search");
      $(".mob-menu-items").toggleClass("hidden-search");
      $("body").css({ overflow: "hidden" });
      $("#nav-onpage").css({ display: "none" });
      $("#nav-onpage").addClass("scroll-stop");
      $("#searchField").focus();
    });

    if ($("body.search-result-page").length) {
      // for search result page

      jQuery(".search-result-link").on("click", function () {
        var scrollDuration = 500;
        var cosParameter = window.scrollY / 2,
          scrollCount = 0,
          oldTimestamp = performance.now();
        function step(newTimestamp) {
          scrollCount +=
            Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
          if (scrollCount >= Math.PI) window.scrollTo(0, 0);
          if (window.scrollY === 0) return;
          window.scrollTo(
            0,
            Math.round(cosParameter + cosParameter * Math.cos(scrollCount))
          );
          oldTimestamp = newTimestamp;
          window.requestAnimationFrame(step);
        }
        window.requestAnimationFrame(step);
      });
    }

    if ($(window).width() < 500) {
      if ($("#inputLoad").length) {
        // console.log('Length: ', $("#inputLoad").length);
        $("#inputLoad").load(
          "/wp-content/themes/fenix4/parts/content-single-location-input.php",
          function (responseTxt, statusTxt, xhr) {
            if (statusTxt == "success")
              if (statusTxt == "error")
                // console.log('Status: ', statusTxt);
                console.log("Status: ", statusTxt);
          }
        );
      }
    } else {
      if ($("body.location-single").length) {
        var d = new Date();

        $(".form-group #date").flatpickr({
          dateFormat: "Y-m-d",
          minDate: "today",
          enableTime: false,
          time_24hr: true,
          //defaultDate:d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate(),
          position: "below",
          disableMobile: true,
          static: true,
          locale: {
            firstDayOfWeek: 1,
            weekdays: {
              shorthand: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
              longhand: [
                "Söndag",
                "Måndag",
                "Tisdag",
                "Onsdag",
                "Torsdag",
                "Fredag",
                "Lördag",
              ],
            },
            months: {
              shorthand: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "Maj",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Okt",
                "Nov",
                "Dec",
              ],
              longhand: [
                "Januari",
                "Februari",
                "Mars",
                "April",
                "Maj",
                "Juni",
                "Juli",
                "Augusti",
                "September",
                "Oktober",
                "November",
                "December",
              ],
            },
          },
        });
      }
      // console.log($(window).width());
    }

    $("#searchSwitcherClose").on("click", function () {
      $("#menuItemsWrapper").toggleClass("hidden-search");
      $("#searchToShow").toggleClass("hidden-search");
      $("#searchField").attr("style", "");
      $("form.form-inline input").val("");
    });

    if ($(window).width() >= 576 && $(window).width() < 769) {
      $("#searchSwitcherClose").on("click", function () {
        $(
          ".navbar:not(#topbar) .container > *:not(#searchToShow) "
        ).toggleClass("hidden-search");
      });
    }

    $("#searchSwitcherCloseMob").on("click", function () {
      $("#searchToShow").toggleClass("hidden-search");
      $("#first-nav.navbar-brand").toggleClass("hidden-search");
      $(".mob-menu-items").toggleClass("hidden-search");
      $("#searchField").attr("style", "");
      $("form.form-inline input").val("");
      $("body").css({ overflow: "visible" });
      $("#nav-onpage").removeClass("scroll-stop");
    });

    $(".close-search-result").click(function (e) {
      $("form.form-inline input").val("");
    });

    function checkSearchFieldVal() {
      var searchInputVal = $("#searchField").val();
      if (searchInputVal == "") {
        $("#searchField").attr("style", "border: 1px solid red;");
        return false;
      } else {
        $("#searchField").attr("style", "");
        return true;
      }
    }

    $("#triggerPressSubmit").on("click", function () {
      var valid = checkSearchFieldVal();
      if (valid) {
        var serch_val = $("#searchField").val();
        window.location.href = "/search/?search-field=" + serch_val;
      }
    });

    $("#searchField").on("keyup", function () {
      checkSearchFieldVal();
    });

    $("#searchField").keydown(function (e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        var valid = checkSearchFieldVal();
        if (valid) {
          $("#pressSubmit").trigger("click");
        }
      }
    });

    function initJQueryClicks() {
      $("#topbar-city-dropdown > div > div > button").on(
        "click",
        "*",
        function (e) {
          $("#menuItemsWrapper").removeClass("hidden-search");
          $("#searchToShow").addClass("hidden-search");
          $("#searchField").attr("style", "");
          $("form.form-inline input").val("");
        }
      );

      $("#topbar").on("click", function () {
        $("#menuItemsWrapper").removeClass("hidden-search");
        $("#searchToShow").addClass("hidden-search");
        $("#searchField").attr("style", "");
        $("form.form-inline input").val("");
      });

      $("#topbar").on("click", "*", function () {
        $("#menuItemsWrapper").removeClass("hidden-search");
        $("#searchToShow").addClass("hidden-search");
        $("#searchField").attr("style", "");
        $("form.form-inline input").val("");
      });
    }

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    var swiper = new Swiper(
      ".services-container, .card-container, .reco-swiper, .media-logo-swiper",
      {
        slidesPerView: "auto",
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      }
    );

    $("input[name=toggle]").on("click", function () {
      console.log($("input[name=toggle]:checked", "#organiser").val());
      console.log($("input[name=toggle2]:checked", "#organiser").val());
    });

    $("input[name=toggle2]").on("click", function () {
      console.log($("input[name=toggle]:checked", "#organiser").val());
      console.log($("input[name=toggle2]:checked", "#organiser").val());
    });

    $("#back-link").on("click", function () {
      console.log($("input[name=toggle]:checked", "#organiser").val());
      console.log($("input[name=toggle2]:checked", "#organiser").val());
    });

    // Organiser buttons on:
    //  page-id-128 - HOME Start page
    //  page-id-143 - /typ-av-begravning/
    if (
      $("body.page-id-128").length ||
      $("body.page-id-143").length ||
      $("body.single-post").length
    ) {
      $(".two-buttons label").each(function () {
        $(this).on("click", function () {
          $(".two-buttons label")
            .removeClass("btn-outline-primary")
            .addClass("btn-light");
          $(this).toggleClass("btn-outline-primary btn-light");
        });
      });

      $(".three-buttons label").each(function () {
        $(this).on("click", function () {
          const labelFor = $(this).attr("for");
          const inputValue = $(
            ".three-buttons " + "input[id=" + labelFor + "]"
          ).val();

          $(".three-buttons label")
            .removeClass("btn-outline-primary")
            .addClass("btn-light");
          $(this).toggleClass("btn-outline-primary btn-light");

          if (inputValue === "internet") {
            $("#external-link").removeClass("hidden-item");
            $("#internal-link").addClass("hidden-item");
            $(".pToShow").addClass("hidden-item");
            $(".pToHide").removeClass("hidden-item");
            $(".pToShow2").addClass("hidden-item");
          } else if (inputValue === "telefon") {
            $("#external-link").addClass("hidden-item");
            $("#internal-link").removeClass("hidden-item");
            $(".pToShow").removeClass("hidden-item");
            $(".pToHide").addClass("hidden-item");
            $(".pToShow2").addClass("hidden-item");
          } else if (inputValue === "kontor") {
            $("#external-link").addClass("hidden-item");
            $("#internal-link").removeClass("hidden-item");
            $(".pToShow2").removeClass("hidden-item");
            $(".pToShow").addClass("hidden-item");
            $(".pToHide").addClass("hidden-item");

            // $('form.organiser .toggle-text').html('<div>• du sparar 1 000 kr via internet</div>');
          }
        });
      });

      $("#internal-link").on("click", function () {
        $("#org-first-div").addClass("hidden-item");
        $("#org-second-div").removeClass("hidden-item");
      });

      $("#kontakta-mig").on("click", function () {
        var validOrganiser = jQuery("#organiser").valid();
        if (validOrganiser) {
          var typeFun = $("input[name=toggle]:checked", "#organiser").val();
          var typeKont = $("input[name=toggle2]:checked", "#organiser").val();

          jQuery.ajax({
            type: "POST",
            url: "/wp-content/themes/fenix4/pages/ajax-submit.php",
            data:
              "organizername=" +
              jQuery("#ange-namn").val() +
              "&organizerphone=" +
              jQuery("#ange-telefonnummer").val() +
              "&organizeremail=" +
              jQuery("#e-mail").val() +
              "&contact=" +
              typeKont +
              "&type=" +
              typeFun +
              "&citycookie=" +
              cityName,
            success: function (result) {
              $("#org-second-div").addClass("hidden-item");
              $("#org-third-div").removeClass("hidden-item");
            },
          });
        }
        return false;
      });

      $("#back-link").on("click", function () {
        $("#org-second-div").addClass("hidden-item");
        $("#org-first-div").removeClass("hidden-item");
      });
    } // if page-128 / -143 / single-post

    // FILTER
    $("#filter-button").on("click", function () {
      $("#filter-row").toggleClass("filter-row-fixed filter-row-auto");
      $("#filter-col").toggleClass("tags-col-absolute tags-col-relative");
    });

    var today = new Date();
    //Init date and time picker in Boka mote modal
    // $("#person-date").flatpickr(
    //     {
    //         minDate: "today",
    //         enableTime:false,
    //         time_24hr: true,
    //         defaultDate:today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
    //         position: "below",
    //         disableMobile: true,
    //         static: true,
    //         altInput: true,
    //         altFormat: "j. F Y",
    //         dateFormat: "Y-m-d",
    //         locale: {
    //             firstDayOfWeek: 1,
    //             weekdays: { shorthand: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
    //                 longhand: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"] },
    //             months: {
    //                 shorthand: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
    //                 longhand: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"]},
    //         },
    //     }
    // );

    $("#personSubmit").on("click", function () {
      //var dateValue = $("#person-date").val();
      var personContactType = $("#person-contactselect").val();
      var personCity = cityName;
      //var personTime = $('input[name=personRadios]:checked').val();

      var validPerson = $("#personForm").valid();
      if (validPerson) {
        var personNamn = $("#person-namn").val();
        var personefternamn = $("#person-efternamn").val();
        var personTel = $("#person-telefonnummer").val();
        var personEpost = $("#person-epost").val();
        var personId = $("#personId").val();
        var advisorname = $("#advisorname").val();

        jQuery.ajax({
          type: "POST",
          url: "/wp-content/themes/fenix4/pages/ajax-submit.php",
          data:
            "personNamn=" +
            personNamn +
            "&personefternamn=" +
            personefternamn +
            "&personTel=" +
            personTel +
            "&personEpost=" +
            personEpost +
            "&personContactType=" +
            personContactType +
            "&personCity=" +
            personCity +
            "&personId=" +
            personId +
            "&advisorname=" +
            advisorname,

          success: function (result) {},
          error: function (data) {
            console.log(data);
          },
        });
        jQuery.ajax({
          type: "POST",
          url: "/wp-content/themes/fenix4/mailer/mailtokund_individualdirector.php",
          // data: 'personNamn='+personNamn+'&personTel='+personTel+'&personEpost='+personEpost+'&personContactType='+personContactType+'&personCity='+personCity+'&personTime='+personTime+'&dateValue='+dateValue,
          data:
            "personNamn=" +
            personNamn +
            "&personefternamn=" +
            personefternamn +
            "&personTel=" +
            personTel +
            "&personEpost=" +
            personEpost +
            "&personContactType=" +
            personContactType +
            "&personCity=" +
            personCity +
            "&personId=" +
            personId +
            "&advisorname=" +
            advisorname,

          success: function (result) {
            jQuery("#personForm")[0].reset();
            window.location.href =
              web_site + "/" + dataFromJson.page_tack_medceremoni_slug + "/";
          },
          error: function (data) {
            console.log(data);
          },
        });
      }

      return false;
    });

    // Lead form A/B test
    $("#leadFormSubmit").on("click", function () {
      var leadForm = $("#leadForm").valid();
      if (cityName === "Välj ditt område" || cityName === "") {
        var cityTitle = "Sverige";
      } else {
        var cityTitle = cityName;
      }

      if (leadForm) {
        var namnLead = $("#lead-namn").val();
        var telLead = $("#lead-telefonnummer").val();

        jQuery.ajax({
          type: "POST",
          url: "/wp-content/themes/fenix4/pages/ajax-submit.php",
          data:
            "namnLead=" +
            namnLead +
            "&telLead=" +
            telLead +
            "&cityTitle=" +
            cityTitle,

          success: function (result) {
            $(
              "#leadForm .lead-form__fields, #leadFormSubmit, #leadForm .lead-form__wrapper .organiser__reco"
            ).addClass("hide-item");
            $("#leadMoteForm-tack").removeClass("hidden-item");
            setTimeout(function () {
              jQuery("#leadForm")[0].reset();
              $(
                "#leadForm .lead-form__fields, #leadFormSubmit, #leadForm .lead-form__wrapper .organiser__reco"
              ).removeClass("hide-item");
              $("#leadMoteForm-tack").addClass("hidden-item");
            }, 3000);
          },
          error: function (data) {
            $(
              "#leadForm .lead-form__fields, #leadFormSubmit, #leadForm .lead-form__wrapper .organiser__reco"
            ).addClass("hide-item");
            $("#leadMoteForm-error").removeClass("hidden-item");
            // alert("error");
            console.log(data);
          },
        });

        jQuery.ajax({
          type: "POST",
          url: "/wp-content/themes/fenix4/mailer/mailtokund_lead.php",
          data:
            "namnLead=" +
            namnLead +
            "&telLead=" +
            telLead +
            "&cityTitle=" +
            cityTitle,
          success: function (result) {},
          error: function (data) {
            //console.log(data);
          },
        });
      }
      return false;
    });

    //Init date and time picker in Boka mote modal
    $("#person-date").flatpickr({
      minDate: "today",
      enableTime: false,
      time_24hr: true,
      defaultDate:
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate(),
      position: "below",
      disableMobile: true,
      static: true,
      altInput: true,
      altFormat: "j. F Y",
      dateFormat: "Y-m-d",
      // onChange: function(selectedDates, dateStr, instance) {
      //     selectedDates.forEach(function (date){
      //
      //         console.log(date.getFullYear(), date.getDate(), date.getMonth()+1);
      //     })
      // },
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
          longhand: [
            "Söndag",
            "Måndag",
            "Tisdag",
            "Onsdag",
            "Torsdag",
            "Fredag",
            "Lördag",
          ],
        },
        months: {
          shorthand: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Maj",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dec",
          ],
          longhand: [
            "Januari",
            "Februari",
            "Mars",
            "April",
            "Maj",
            "Juni",
            "Juli",
            "Augusti",
            "September",
            "Oktober",
            "November",
            "December",
          ],
        },
      },
    });

    // Kontakta oss form
    $("#kontakta-oss").on("click", function () {
      var $this = $(this);
      var validKontaktaOss = $("#kontaktaOssForm").valid();
      if (validKontaktaOss) {
        $this.prop("disabled", true).addClass("disabled");
        const dataToSubmit = {
          kontaktanamn: $("#ditt-namn").val(),
          kontaktafornamn: $("#ditt-fornamn").val(),
          kontaktatelefon: $("#ditt-telefonnummer").val(),
          kontaktaepost: $("#ditt-epost").val(),
          meddelande: $("#ditt-meddelande").val(),
          sourcetype: 3,
          type: 1,
          citycookie: cityName,
        };

        jQuery.ajax({
          type: "POST",
          dataType: "json",
          data: dataToSubmit,
          url: "/wp-content/themes/fenix4/pages/ajax-submit.php",
          // data: 'kontaktanamn='+namn+'&kontaktatelefon='+tel+'&kontaktaepost='+epost+'&meddelande='+meddelande+'&sourcetype='+typeKont+'&type='+typeFun+'&citycookie='+cityName,

          success: function (result) {
            $("#kontaktaOssForm .modal-body, #kontakta-oss").addClass(
              "hidden-item"
            );
            $("#kontaktaOssForm-tack").removeClass("hidden-item");
            $this.prop("disabled", false).removeClass("disabled");
          },
          error: function (data) {
            $("#kontaktaOssForm .modal-body, #kontakta-oss").addClass(
              "hidden-item"
            );
            $("#kontaktaOssForm-error").removeClass("hidden-item");
            $this.prop("disabled", false).removeClass("disabled");
          },
        });

        // EXTRA-3: Send Kontakta oss copy to KUND mail-box
        jQuery.ajax({
          type: "POST",
          dataType: "json",
          data: dataToSubmit,
          url: "/wp-content/themes/fenix4/mailer/mailtokund_kontaktaoss.php",
          // data: 'kontaktanamn='+namn+'&kontaktatelefon='+tel+'&kontaktaepost='+epost+'&meddelande='+meddelande+'&sourcetype='+typeKont+'&type='+typeFun+'&citycookie='+cityName,
        });
      } // if
      // console.log('I will return nothing...');
      return false;
    });

    // Organize form
    $("#organizeForm").submit(function (event) {
      if (!$("#organizeForm").valid()) {
        return false;
      }
      $.ajax({
        type: "POST",
        url: "/wp-content/themes/fenix4/pages/ajax-submit.php",
        data: $("#organizeForm").serialize(),
        success: function (result) {
          document.location.href = window.organizeform.redirect;
        },
      });
      event.preventDefault();
    });

    //Init date and time picker in Boka mote modal
    $("#boka-date-time").flatpickr({
      dateFormat: "Y-m-d",
      minDate: "today",
      enableTime: false,
      time_24hr: true,
      defaultDate:
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate(),
      position: "below",
      disableMobile: true,
      static: true,
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
          longhand: [
            "Söndag",
            "Måndag",
            "Tisdag",
            "Onsdag",
            "Torsdag",
            "Fredag",
            "Lördag",
          ],
        },
        months: {
          shorthand: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Maj",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dec",
          ],
          longhand: [
            "Januari",
            "Februari",
            "Mars",
            "April",
            "Maj",
            "Juni",
            "Juli",
            "Augusti",
            "September",
            "Oktober",
            "November",
            "December",
          ],
        },
      },
    });

    $("input[name=timeRadio]").on("change", function (e) {
      var inputTimeVal = $("input[name=timeRadio]:checked").val();
      var flatpickrWrapper = document.querySelector(
        "#bokaMoteForm .flatpickr-wrapper"
      );
      if (inputTimeVal == "Önska tidpunkt") {
        flatpickrWrapper.style.display = "block";
      } else {
        flatpickrWrapper.style.display = "none";
      }
    });

    $(".form-group--time label").on("click", function () {
      $(this)
        .closest(".form-check-inline")
        .find(".form-check-input")
        .trigger("click");
    });

    // funnel form
    $("#funnelClientDetails").submit(function (event) {
      if (!$("#funnelClientDetails").valid()) {
        return false;
      }
      $.ajax({
        type: "POST",
        url: "/wp-content/themes/fenix4/pages/ajax-submit.php",
        data:
          $("#funnelClientDetails").serialize() +
          "&products=" +
          window.productsToSubmit +
          window.funnelform.data,
        success: function (result) {
          document.location.href = window.funnelform.redirect;
        },
        error: function (data) {
          alert("Error: Spam?");
          console.error("Spam? " + data);
        },
      });

      event.preventDefault();
    });

    // labels managing
    function initInput(n, o) {
      $.each($(n), function (n, t) {
        var i = $(t),
          e = i.siblings(o);
        i.on("blur focus", function (n) {
          i.is(":focus") || i.val()
            ? e.addClass("active-label")
            : e.removeClass("active-label");
        });
      });
    }

    initInput(".form-control__input", ".form-group__label"); // label managing

    $(".form-control__select").on("change", function () {
      $(this)
        .closest(".form-group--contact")
        .find("label")
        .addClass("active-label");
    });

    $(".form-control__textarea").on("focus", function () {
      $(this)
        .closest(".form-group--textarea")
        .find("label")
        .addClass("active-label");
    });

    $(".form-control__textarea").on("blur", function () {
      if (!$(".form-control__textarea").val()) {
        $(this)
          .closest(".form-group--textarea")
          .find("label")
          .removeClass("active-label");
      }
    });

    $(
      ".header-top-bar__exp, .one-button__exp, .table-block__button>.bookMeeting, .mob-button__exp"
    ).on("click", function () {
      var textareaValue = $(".form-control__textarea").val();
      var inputsValue = $(".form-control__input");
      var selectValue = $(".form-control__select");

      $.each($(inputsValue), function (inputsValue, t) {
        var i = $(t),
          e = i.siblings(".form-group__label");
        i.val() ? e.addClass("active-label") : e.removeClass("active-label");
      });

      $.each($(selectValue), function (selectValue, l) {
        var y = $(l),
          s = y.siblings(".form-select__label");
        y.val() ? s.addClass("active-label") : s.removeClass("active-label");
      });

      if (textareaValue) {
        $("#bokaMoteForm textarea")
          .closest(".form-group--textarea")
          .find("label")
          .addClass("active-label");
      }
    });

    // Book meeting modal form
    $("#bokaMoteSubmit").on("click", function () {
      var $this = $(this);
      var inputTimeVal = $("input[name=timeRadio]:checked").val();

      var dateTimeValue = $("#boka-date-time").val();
      if (inputTimeVal == "Snarast") {
        var dateTimeValue = "Snarast";
      } else {
        var dateTimeValue = $("#boka-date-time").val();
      }

      var officeCityNameToApi;
      var officeCityNameToApiText = jQuery("span#bokaOfficeCityName").text();

      if (officeCityNameToApiText === "") {
        officeCityNameToApi = "Sverige";
      } else {
        if (officeCityNameToApiText.indexOf("i ") !== -1) {
          officeCityNameToApi = officeCityNameToApiText.replace(/i /g, "");
        } else {
          officeCityNameToApi = officeCityNameToApiText.replace(/på /g, "");
        }
      }

      var validBokaMote = $("#bokaMoteForm").valid();
      if (validBokaMote) {
        $this.prop("disabled", true).addClass("disabled");
        const dataToSubmit = {
          bookmeeting: getCookie("fenix4city"),
          namn: $("#boka-namn").val(),
          fornamn: $("#boka-fornamn").val(),
          telefon: $("#boka-telefonnummer").val(),
          epost: $("#boka-epost").val(),
          sourcetype: 3,
          type: 1,
          officecity: officeCityNameToApi,
          comment: $("#boka-meddelande").val(),
          helpType: $("#boka-hjalpselect").val(),
          contactType: $("#boka-contactselect").val(),
          dateTime: dateTimeValue,
        };

        jQuery.ajax({
          type: "POST",
          dataType: "json",
          url: "/wp-content/themes/fenix4/pages/ajax-submit.php",
          data: dataToSubmit,
          success: function (result) {
            $(
              "#bokaMoteForm .modal-body, #bokaMoteSubmit, #bokaMoteForm .modal-footer-note, #bokaMoteForm #reco-rating"
            ).addClass("hidden-item");
            $("#bokaMoteForm-tack").removeClass("hidden-item");
            $this.prop("disabled", false).removeClass("disabled");
            setTimeout(function () {
              window.location.href =
                web_site + "/" + dataFromJson.page_tack_medceremoni_slug + "/";
            }, 3000);
          },
          error: function (data) {
            $(
              "#bokaMoteForm .modal-body, #bokaMoteSubmit, #bokaMoteForm .modal-footer-note, #bokaMoteForm #reco-rating"
            ).addClass("hidden-item");
            $("#bokaMoteForm-error").removeClass("hidden-item");
            $this.prop("disabled", false).removeClass("disabled");
          },
        });

        // EXTRA-2: Send Boka mote copy to KUND mail-box
        jQuery.ajax({
          type: "POST",
          dataType: "json",
          url: "/wp-content/themes/fenix4/mailer/mailtokund_bokamote.php",
          data: dataToSubmit,
        });
      } // END: if (validBokaMote)
      // console.log('I will return nothing...');
      return false;
    });

    $('li[aria-current="page"] a').click(function (event) {
      event.preventDefault();
    });

    //New form AB test start
    let contactReact = $("#contactForm").validate({
      rules: {
        name: {
          required: true,
          lettersonly: true,
        },
        surname: {
          required: true,
          lettersonly: true,
        },
        phone: {
          required: true,
          fenixSEPhone: true,
        },
      },
      focusCleanup: true,
      onfocusout: false,
      onkeyup: false,
      onclick: false,
    });

    let newPwSwiper = new Swiper(".contact-swiper", {
      updateOnWindowResize: true,
      slidesPerView: 1,
      observer: true,
      observeParents: true,
      grabCursor: false,
      keyboard: false,
      allowTouchMove: false,
      mousewheel: false,
      simulateTouch: false,
      passiveListeners: false,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      centeredSlides: true,
      pagination: {
        el: ".contact-pagination",
        type: "custom",
        clickable: true,
        renderCustom: function (swiper, current, total) {
          return (
            '<span class="swiper-pagination-current">' +
            current +
            '</span>/<span class="swiper-pagination-total">' +
            (total - 1) +
            "</span>"
          );
        },
      },
    });

    $("#firstStep").on("click", function () {
      newPwSwiper.slideNext();
    });

    $("#secondStep").on("click", function () {
      newPwSwiper.slideNext();
    });

    $("#closeContactForm, #rewind-slider, .close-slider").on(
      "click",
      function () {
        $("#contactForm")[0].reset();
        newPwSwiper.slideTo(0);
        $("#secondStep").attr("disabled", true);
        $("#secondStep").addClass("not-active");
        $("#finalStep").attr("disabled", true);
        $("#finalStep").addClass("not-active");
      }
    );

    $("#finalStep").on("click", function (e) {
      let validBokaMotePW = $("#contactForm").valid();
      if (validBokaMotePW) {
        $("#finalStep").prop("disabled", true);
        const dataToSubmit = {
          bookmeetingcity: getCookie("fenix4city"),
          namn: $("#nameInputReact").val(),
          fornamn: $("#surnameInputReact").val(),
          telefon: $("#phoneInputReact").val(),
          sourcetype: 3,
          type: 1,
          serviceType: $(
            ".question-input input[name=serviceType]:checked"
          ).val(),
        };

        jQuery.ajax({
          type: "POST",
          dataType: "json",
          url: "/wp-content/themes/fenix4/pages/ajax-submit.php",
          data: dataToSubmit,
          success: function (result) {
            newPwSwiper.slideNext();
            $("#name-react").text($("#nameInputReact").val());
            $("#surname-react").text($("#surnameInputReact").val());
            $("#phone-react").text($("#phoneInputReact").val());
          },
          error: function (data) {
            console.log(data);
          },
        });

        jQuery.ajax({
          type: "POST",
          dataType: "json",
          url: "/wp-content/themes/fenix4/mailer/mailtokund_book.php",
          data: dataToSubmit,
        });
      }
      e.preventDefault();
      return false;
    });

    $(".swiper-contact-prev").on("click", function () {
      newPwSwiper.slidePrev();
    });

    $("input[name=serviceType]").on("change", function () {
      if (
        $(".question-input input[name=serviceType]:checked").val() === "Juridik"
      ) {
        $("#response-first").text("Då har du kommit rätt.");
      } else {
        $("#response-first").text("Vi beklagar sorgen.");
      }
    });

    $(".question-input input[name=serviceType]:checked").val();

    $("#nameInputReact").on("input", function () {
      contactReact.element("#nameInputReact");
      if (
        contactReact.element("#nameInputReact") &&
        contactReact.element("#surnameInputReact")
      ) {
        $("#secondStep").removeClass("not-active");
        $("#secondStep").removeAttr("disabled");
      } else {
        $("#secondStep").attr("disabled", true);
        $("#secondStep").addClass("not-active");
      }
    });

    $("#surnameInputReact").on("input", function () {
      contactReact.element("#surnameInputReact");
      if (
        contactReact.element("#nameInputReact") &&
        contactReact.element("#surnameInputReact")
      ) {
        $("#secondStep").removeClass("not-active");
        $("#secondStep").removeAttr("disabled");
      } else {
        $("#secondStep").attr("disabled", true);
        $("#secondStep").addClass("not-active");
      }
    });

    $("#phoneInputReact").on("input", function () {
      contactReact.element("#phoneInputReact");
      if (contactReact.element("#phoneInputReact")) {
        $("#finalStep").removeClass("not-active");
        $("#finalStep").addClass("active");
        $("#finalStep").removeAttr("disabled");
      } else {
        $("#finalStep").attr("disabled", true);
        $("#finalStep").addClass("not-active");
        $("#finalStep").removeClass("active");
      }
    });
    //New form AB test finish

    // Initialize Swiper
    var swiper = new Swiper(
      ".services-container, .card-container, .reco-swiper, .media-logo-swiper",
      {
        slidesPerView: "auto",
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      }
    );

    // Plus-Minus Quantity buttons
    $("#minus-qty").click(function () {
      // console.log('minus pressed');
      var currentValue = parseInt($("#calc-qty").text());
      // console.log('aaa', currentValue, typeof(currentValue));

      if (currentValue > 1) {
        currentValue = currentValue - 1;
      } else {
        currentValue = 1;
      }

      $("#calc-qty").text(currentValue);
    });

    $("#plus-qty").click(function () {
      // console.log('plus pressed');
      var currentValue = parseInt($("#calc-qty").text());
      // console.log('aaa', currentValue, typeof(currentValue));

      if (currentValue < 10) {
        currentValue = currentValue + 1;
      } else {
        currentValue = 10;
      }

      $("#calc-qty").text(currentValue);
    });

    // Second version
    $(".small-minus-qty").click(function () {
      event.stopPropagation();
      // console.log('small minus pressed');
      var currentValueCell = $(this)
        .closest(".funnel-added-product")
        .find(".small-calc-qty");
      var currentValue = parseInt(currentValueCell.val());
      // console.log('aaa', currentValue);

      if (currentValue > 1) {
        currentValue = currentValue - 1;
      } else {
        currentValue = 1;
      }

      currentValueCell.val(currentValue);
    });

    $(".small-plus-qty").click(function () {
      event.stopPropagation();
      // console.log('small plus pressed');
      var currentValueCell = $(this)
        .closest(".funnel-added-product")
        .find(".small-calc-qty");
      var currentValue = parseInt(currentValueCell.val());
      // console.log('aaa', currentValue);

      if (currentValue < 10) {
        currentValue = currentValue + 1;
      } else {
        currentValue = 10;
      }

      currentValueCell.val(currentValue);
    });

    $("#select-style").change(function () {
      var optionValue = $("#select-style").val();
      var optionId = $("#" + optionValue);
      var optionIdValue = $("#" + optionValue).val();
      var dataPlace = optionId.data("ceremony_place");
      var dataDate = optionId.data("ceremony_date");
      var dataTime = optionId.data("ceremony_time");
      var dataDeceasedName = optionId.data("deceased_name");

      $("#addinfoloc").val(dataPlace);
      $("#addinfodate").val(dataDate);
      $("#addinfotime").val(dataTime);
      $("#addinfoname").val(dataDeceasedName);
      // return dataDeceasedName;
      $("#addinfoname-error").hide();
    });

    $("#rsvpFormReset").click(function () {
      $("#rsvp")[0].reset();
    });

    $("#newRsvpForm").click(function () {
      window.location.href =
        web_site +
        "/" +
        dataFromJson.role_ordna_begravning_slug +
        "/" +
        dataFromJson.prod_cat_slug +
        "/" +
        dataFromJson.prod_cat_blommor_slug +
        "/";
    });

    $("#rsvpFormSubmit").click(function (e) {
      e.preventDefault();

      // console.log('!!!!!');

      /* roll-back in case diet field value was deleted */
      if ($("#allergier").val() == "") {
        $("#allergier").val(dataFromJson.allergier);
      }

      if (!$("#select-style").val()) {
        // console.error('Begravning not selected');
        $("#addinfoname-error").show();
        checkFormSuccess = 0;
        scrollToTop($(".memory-notification-wrapper"));
      } else {
        var validRsvp = $("#rsvp").valid();
        if (validRsvp) {
          var dropdown = $("#select-style").val();
          var email = $("#email").val();
          var angenamn = $("#angenamn").val();
          var allergier = $("#allergier").val();
          var telefonnummer = $("#telefonnummer").val();
          var angeantal = $("#angeantal").val();
          var deceasedname = $("#addinfoname").val();
          var kontakta = $("#kontakta").prop("checked") ? 1 : 0;
          var jag = $("#jag").prop("checked") ? 1 : 0;

          var formData =
            "dropdown=" +
            encodeURIComponent(dropdown) +
            "&email=" +
            encodeURIComponent(email) +
            "&angenamn=" +
            encodeURIComponent(angenamn) +
            "&allergier=" +
            encodeURIComponent(allergier) +
            "&telefonnummer=" +
            encodeURIComponent(telefonnummer) +
            "&kontakta=" +
            encodeURIComponent(kontakta) +
            "&angeantal=" +
            encodeURIComponent(angeantal) +
            "&deceasedname=" +
            encodeURIComponent(deceasedname);
          console.log("formData", formData);

          $.post(
            "/wp-content/themes/fenix4/php/submit_bitnet.php",
            formData
          ).done(function (data) {
            //                            alert( "Data Loaded: " + data );
            window.location.href =
              "/" + dataFromJson.page_anmalan_slug + "/?dropdown=yes";
          });

          $("#rsvp-thanks").show();
          $("#rsvp").hide();
          $("#rsvp-image").hide();

          $("html, body").animate(
            { scrollTop: $("#main").offset().top },
            "slow"
          );
        } // END: if (validRsvp)
      } // END: if ( !$('#select-style').val()...)
    });
  }); // END of $( document ).ready(...)

  // Plus-Minus Quantity buttons - DEN Edit for multiple plus/minus blocks
  $(".minus-qty").click(function () {
    // console.log('minus pressed');
    var currentValueCell = $(this)
      .closest(".single-quantity-wrapper")
      .find(".single-quantity-number");
    var currentValue = parseInt(currentValueCell.text());
    var currentValueVal = parseInt(currentValueCell.val());
    // console.log('single-quantity-number', currentValue, typeof(currentValue));

    if (currentValue > 1) {
      currentValue = currentValue - 1;
    } else {
      currentValue = 1;
    }

    if (currentValueVal > 1) {
      currentValueVal = currentValueVal - 1;
    } else {
      currentValueVal = 1;
    }

    currentValueCell.text(currentValue);
    currentValueCell.val(currentValueVal);
  });

  $(".plus-qty").click(function () {
    // console.log('plus pressed');
    var currentValueCell = $(this)
      .closest(".single-quantity-wrapper")
      .find(".single-quantity-number");
    var currentValue = parseInt(currentValueCell.text());
    var currentValueVal = parseInt(currentValueCell.val());
    // console.log('single-quantity-number', currentValue, typeof(currentValue));
    // console.log('currentValueCell', currentValueCell);

    if (currentValue < 10) {
      currentValue = currentValue + 1;
    } else {
      currentValue = 10;
    }

    if (currentValueVal < 10) {
      currentValueVal = currentValueVal + 1;
    } else {
      currentValueVal = 10;
    }
    currentValueCell.text(currentValue);
    currentValueCell.val(currentValueVal);
  });

  // Remove element from Checkout
  $(".cart-remove-button").click(function () {
    $(this).closest(".cart-row").remove();
  });

  $(".search-result-row").click(function () {
    $("form.form-inline input").val($.trim($(this).children("div").text()));
    $("#searchField").attr("style", "");
    $("#pressSubmit").trigger("click");
  });

  $(".tab-item").click(function () {
    $(".tab-item").removeClass("active-tab");
    $(this).addClass("active-tab");
    var index = $(this).index() + 1;
    // console.log("index", index);
    $(".search-tabs-content").addClass("hidden-result");
    // console.log(".content-"+index);
    $(".content-" + index).removeClass("hidden-result");
    // scrollToTop2( $('body') );
  });

  function scrollToTop(el, offset) {
    if (offset === undefined) {
      offset = 0;
    }
    if ($(window).width() > 575) {
      // offset = 180;
      offset = -110;
    }
    // console.log('offset ', offset);

    var objToScroll = el;
    setTimeout(function () {
      $("html, body")
        .delay(10)
        .animate({ scrollTop: objToScroll.offset().top + offset }, 400);
    }, 300);
    // console.log('I scrolled!')
  }

  $("#offices-result-link").click(function (e) {
    e.preventDefault();
    $(".tab-item:nth-child(2)").trigger("click");
  });

  $("#articles-result-link").click(function (e) {
    e.preventDefault();
    $(".tab-item:nth-child(3)").trigger("click");
  });

  $("#locations-result-link").click(function (e) {
    e.preventDefault();
    $(".tab-item:nth-child(4)").trigger("click");
  });

  $("#products-result-link").click(function (e) {
    e.preventDefault();
    $(".tab-item:nth-child(5)").trigger("click");
  });

  $("form.form-inline input").submit(function (event) {
    event.preventDefault();
    $("form.form-inline input").val("");
  });

  $("#external-link").click(function (e) {
    e.preventDefault();
    var packageNum = 1;
    var toggleName = $("input[name=toggle]:checked", "#organiser").val();
    var typeFun = $("input[name=toggle]:checked", "#organiser").val();
    if (toggleName == "med") packageNum = 1;
    if (toggleName == "utan") packageNum = 2;
    //                alert(toggleName + packageNum);
    window.location.href =
      "/" +
      dataFromJson.planera_slug +
      "/?package=" +
      packageNum +
      "&type=" +
      typeFun;
  });

  // FROM fenix4_scripts-den.js

  // Autoplay video in Bootstrap modal window on modal opening
  $("#videoStory").on("shown.bs.modal", function () {
    $("#fenixStoryVideo")[0].play();
  });
  $("#videoStory").on("hidden.bs.modal", function () {
    $("#fenixStoryVideo")[0].pause();
    $("#fenixStoryVideo")[0].currentTime = 0;
  });

  // hide modal on video end
  $("#fenixStoryVideo").on("ended", function () {
    // console.log('video ended');
    $("#videoStory").modal("hide");
  });

  // pause/play video on click
  $("#fenixStoryVideo").on("click", function () {
    this.paused ? this.play() : this.pause();
  });

  // DEN: Location Check ?locch=true
  // Function to get the dynamic variables from url and store them as JavaScript variables
  // DEV: the function duplicate from /parts/map-city-offices.php
  jQuery.urlParam = function (name) {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
      window.location.href
    );
    if (results == null) {
      return null;
    }
    return decodeURI(results[1]) || 0;
  };

  if (
    jQuery.urlParam("locch") == "true" ||
    jQuery.urlParam("locch") == "yes" ||
    jQuery.urlParam("locch") == "1"
  ) {
    // check if this is NOT first time visitor sees locch popup
    if (
      sessionStorage.getItem("locchConfirmed") !== "yes" &&
      (jQuery("body").hasClass("city-landing") ||
        jQuery("body").hasClass("home") ||
        jQuery("body").hasClass("funeral-type"))
    ) {
      jQuery(".locch-open").click();
      // sessionStorage.setItem('locchModalOpen', 'yes');
    } else {
      // console.log( 'You probably just returned via "Back" history button' );
    }
  }
}); // jQuery
// ALL WORKING SCRIPTS ARE IN fenix4_scripts.MIN.js
